var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CSidebar',{staticStyle:{"position":"relative"},attrs:{"colorScheme":"light","fixed":"","minimize":_vm.minimize,"show":_vm.show},on:{"update:show":function($event){_vm.show=$event}}},[_c('img',{staticClass:"close",attrs:{"src":require("../assets/icons/svg/Close.svg"),"alt":"cerrar"},on:{"click":function($event){_vm.show = false}}}),_c('CSidebarNav',[_c('CSidebarNavItem',{attrs:{"name":"Mapa","to":"/dashboard","icon":{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 11@2x.png')
        }}}),_c('CSidebarNavItem',{attrs:{"name":"Alertas","to":"/alertas","icon":{
            class: 'menu-icons-img',
            src: require('../assets/imgicons/ICONOS SOSRecurso 12@2x.png')
          }}}),(_vm.checkPermissions(['view_cuadrantes']))?_c('CSidebarNavItem',{attrs:{"name":"Cuadrantes","to":"/cuadrantes","icon":{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 30@2x.png')
        }}}):_vm._e(),_c('CSidebarNavItem',{attrs:{"name":"Botones SOS","to":"/buttons","icon":{
            class: 'menu-icons-img',
            src: require('../assets/imgicons/ICONOS SOSRecurso 12@2x.png')
          }}}),_c('CSidebarNavDropdown',{attrs:{"name":"Usuarios Panel","icon":{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 18@2x.png')
        }}},[(_vm.checkPermissions(['isSuperAdmin', 'view_user_admin']))?_c('CSidebarNavItem',{attrs:{"name":"Usuarios SuperAdmin","to":"/superadmin-users","icon":{
            class: 'menu-icons-img',
            src: require('../assets/imgicons/ICONOS SOSRecurso 18@2x.png')
          }}}):_vm._e(),(_vm.checkPermissions(['view_user_admin']))?_c('CSidebarNavItem',{attrs:{"name":"Usuarios Panel","to":"/admin-users","icon":{
            class: 'menu-icons-img',
            src: require('../assets/imgicons/ICONOS SOSRecurso 18@2x.png')
          }}}):_vm._e()],1),(_vm.checkPermissions(['view_user_app']))?_c('CSidebarNavItem',{attrs:{"name":"Usuarios","to":"/users","icon":{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 15@2x.png')
        }}}):_vm._e(),_c('CSidebarNavDivider',{staticClass:"my-2"}),(_vm.role == 9)?_c('CSidebarNavItem',{attrs:{"name":"Configuración","to":"/configuration","icon":{
          class: 'menu-icons-img',
          src: require('../assets/icons/svg/settingsblack.svg'),
        }}}):_vm._e(),_c('CSidebarNavDivider',{staticClass:"my-2"}),_c('div',{on:{"click":_vm.logOut}},[_c('CSidebarNavItem',{attrs:{"name":"Salir","addLinkClasses":"logout-click-handler","icon":{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 29@2x.png')
        }}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }